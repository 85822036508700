@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
	font-family: "Poppins", sans-serif;
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.5rem;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.2;
	color: inherit;
}

.h1,
h1 {
	font-size: 25px !important;
}

.h2,
h2 {
	font-size: 20px !important;
}

.h3,
h3 {
	font-size: 17px !important;
}

.h4,
h4 {
	font-size: 15px !important;
}

.h5,
h5 {
	font-size: 13px !important;
}

.h6,
h6 {
	font-size: 12px;
}

p {
	margin-top: 0;
	margin-bottom: 14px;
}

.small,
small {
	font-size: 80%;
	font-weight: 400;
}

a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}

a:hover {
	color: #0056b3;
	text-decoration: underline;
}

button:focus {
	outline: none !important;
	box-shadow: none !important;
}

.lms-elm-icon {
	width: 128px;
	position: relative;
	top: -6px;
}
.top-header-style {
	height: 73px;
	line-height: 73px;
	overflow: hidden;
	box-shadow: 0 0 20px rgb(170 191 212 / 30%);
}

.course-name-top-header {
	color: #12274b !important;
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;
	margin: 0px;
}

/* .course-name-top-header a {
	color: #12274B  !important;
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;
} */

/* .course-name-top-header::before {
	content: "";
	width: 1px;
	background: #989898;
	height: 40%;
	position: absolute;
	left: 0px;
	top: 13px;
} */

.back-button-e-learn {
	color: #000 !important;
	display: none;
}

.main-container-box {
	display: flex;
}

.left-section-for-elearn {
	width: 75%;
}

.right-section-for-elearn {
	position: fixed;
	right: 0;
	width: 25%;
	top: 75px;
	height: 90%;
	box-shadow: -1px 0px 4px rgb(0 0 0 / 2%);
}

.face-to-face-video {
	width: 100%;
}

.e-learning-content-section {
	/* display: flex; */
	position: relative;
	justify-content: center;
	padding: 40px 0px;
	/* background: linear-gradient(180deg, rgba(245, 243, 253, 0) 0%, #C4D9ED 100%); */
	background-image: url(../images/backgroundImage.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.e-learning-content-section .previous-button {
	position: absolute;
	background: transparent;
	border: none;
	color: #fff;
	top: 50%;
	line-height: 51px;
	width: 42px;
	text-align: center;
	font-size: 25px;
	z-index: 9;
	left: 10px;
}

.e-learning-content-section .next-button {
	position: absolute;
	background: transparent;
	border: none;
	color: #fff;
	top: 50%;
	line-height: 51px;
	width: 42px;
	text-align: center;
	font-size: 25px;
	z-index: 9;
	right: 10px;
}
.next-previous-icon {
	max-width: 100%;
	height: auto;
}

.e-learning-dashboard-section {
	padding: 40px 15px 18px 15px;
	background: #faf9fd;
}

.lms-controller-nav-bar {
	list-style: none;
	border-bottom: 1px dashed #c4d9ed;
	margin-left: 0px;
	padding-left: 0px;
	padding-bottom: 3px;
}

.lms-controller-nav-bar li {
	display: inline-block;
	height: 35px;
	line-height: 35px;
}

.lms-controller-nav-bar li a {
	text-decoration: none;
	padding: 0 27px;
	display: block;
	background: #fff;
	border-radius: 3px 3px 0px 0px;
	font-weight: 500;
	font-size: 14px;
	color: #12274b;
	margin-right: 10px;
}

.active-lms-controller {
	color: #fff !important;
	background: #ee9949 !important;
}

/* .active-lms-controller::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	background: #000;
	left: 0px;
	bottom: -15px;
} */

.only-for-mobile {
	display: none !important;
}

.top-header-lms-menu {
	font-size: 18px;
	font-weight: 600;
	padding: 21px 10px;
	margin: 0;
	background: #faf9fd;
	border-bottom: 1px dashed #c4d9ed;
	margin-left: 2px;
	margin-bottom: 2px;
	padding-bottom: 9px;
	text-transform: uppercase;
}

.close-btn-for-lms-menu {
	float: right;
	color: #12274b !important;
	font-size: 18px;
	position: relative;
	top: -9px;
	cursor: pointer;
}

.display-none-side-menu-lms .left-section-for-elearn {
	width: 100%;
	overflow: hidden;
}

.display-none-side-menu-lms .right-section-for-elearn {
	display: none;
}

.show-side-menu-lms-btn {
	display: none;
	position: absolute;
    right: -148px;
    background: #505761;
    border: none;
    color: #fff;
    height: 42px;
    top: 13px;
    line-height: 24px;
    font-size: 17px;
    z-index: 9;
    padding: 0 8px;
    transition: all .6s;
    min-width: 179px;
    text-align: left;
}

.show-side-menu-lms-btn:hover {
	right: 0px;
	transition: all 0.6s;
}

.display-none-side-menu-lms .show-side-menu-lms-btn {
	display: block;
}

.course-content-list {
	list-style-type: none;
	font-size: 14px;
	font-weight: 500;
	background-color: #fff;
	cursor: pointer;
	position: relative;
	padding: 20px 10px;
	color: #12274b;
	border-bottom: 1px dashed #c4d9ed;
}

/* .course-content-list:hover {
	background-color: #eeeeef;
} */

.course-content-list-active {
	list-style-type: none;
	font-size: 14px;
	font-weight: 500;
	background-color: #fff;
	cursor: pointer;
	position: relative;
	padding: 20px 10px;
	color: #12274b;
	border-bottom: 1px dashed #c4d9ed;
}

.small-text-under-section {
	font-size: 10px;
	font-weight: 400;
	color: #5BC716;
	text-transform: none;
}
.if-not-complete{
	color: #EE9949 !important
}
.chapter-name {
	font-weight: 500;
	font-size: 12px;
	cursor: pointer;
	color: #1d458a;
	display: block;
	padding: 15px 30px 15px 10px;
}

.chapter-block {
	background: #faf9fd;
	margin: 2px;
	border-bottom: 1px dashed #c4d9ed;
	position: relative;
}
.chapter-box {
}
.chapter-collapse {
	position: absolute;
	right: 9px;
	top: 14px;
}

.chapter-collapse .fa {
	color: #12274b;
	font-size: 13px;
}

.chapter-block a {
	color: inherit;
}

.chapter-block a:hover,
a:active {
	color: inherit;
	text-decoration: none;
}

.unit-name {
	font-size: 13px;
	background-color: transparent;
	padding: 15px 31px 15px 10px;
	color: #819ba6;
	font-weight: 400;
}
.unit-name-active {
	background: #f1a854;
	color: #101725;
	font-weight: 600;
}

.check-box-chapter {
	position: absolute;
	right: 0px;
}
.unit-name-box{
    display: block;
    width: 100%;
	overflow: auto;
}
.unit-name-box .fa {
	display: inline-block;
    float: left;
    width: 28px;
    margin-top: 4px;
}
.unit-name-text{
	display: inline-block;
    float: left;
    width: 88%;
    position: relative;
}
.video-time{
	display: block;
    margin-left: 28px;
    font-size: 11px;
    font-weight: 300;
    color: #b7c5cc!important;
    width: 80%;
    float: left;

}
.video-time .fa{
	width: auto;
    float: none;
}
.unit-name input[type="checkbox"] {
	width: 30px;
	height: 17px;
	padding-left: 0.35em;
	float: left;
	display: none;
}
.unit-name input[type="checkbox"] + span {
	float: left;
	position: relative;
	width: 20px;
	height: 19px;
	vertical-align: middle;
	background: transparent;
	border: none;
	cursor: pointer;
	margin-left: 5px;
	margin-right: 3px;
}
.unit-name input[type="checkbox"]:checked + span {
	background: transparent;
	border: none;
}
.unit-name input[type="checkbox"]:checked + span:after {
	content: "\f00c";
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 11px;
	color: #477529;
}
.unit-name input[type="checkbox"] + span:after {
	content: "\f00c";
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 11px;
	color: #e5f0f3;
}

.unit-name input[type="checkbox"] + span {
	margin-right: 4px;
}
.side-menu-for-lms {
	height: 91%;
}

.icon-small {
	font-size: 0.8rem;
}

.section-class {
	height: 444px;
	width: 750px;
	margin: auto;
	border: 2px solid;
	border-radius: 5px;
}

.section-class-webinar {
	height: 600px;
}
.section-class-html {
	font-size: 12px;
	line-height: 24px;
	color: #3c5159;
	padding: 10px 18px;
	background-color: #FFFFFF;
}
.section-class-html .start-btn {
	background: #ee9949;
	box-shadow: 0px 0px 20px rgba(238, 153, 73, 0.2);
	border-radius: 3px;
	color: #fff;
}

.side-menu-navigation {
	position: absolute;
	right: 9px;
	top: 19px;
	color: #ee9949;
}

.chapter-name-hedding {
	position: relative;
	padding-right: 34px;
}

.chapter-name-hedding .side-menu-navigation {
	position: absolute;
	right: 6px;
	top: 6px;
	font-size: 11px;
}
.section-class-test {
	min-height: 500px;
	height: auto;
	width: 750px;
	margin: auto;
}
.course-test {
	background: #ffffff;
	border: 1px solid #d5e7f7;
	box-sizing: border-box;
	box-shadow: 0px 0px 30px rgba(223, 228, 249, 0.5);
	border-radius: 7px;
	padding: 0px 0px 20px 0px;
	overflow: hidden;
}
.course-test-container {
	font-size: 12px;
	line-height: 24px;
	color: #819ba6;
	padding: 10px 18px;
	position: relative;
}

.course-test-container p {
	font-weight: 700;
	color: #000;
}
.course-test-option {
	background: rgba(220, 230, 235, 0.1);
	border: 1px solid #d5e7f7;
	box-sizing: border-box;
	padding: 13px 13px;
	position: relative;
}
.course-test-option p {
	font-weight: 500;
	font-size: 14px;
	color: #6378ae;
	margin-bottom: 0px;
}

.course-test-option input {
	width: 17px;
	height: 17px;
	position: relative;
	top: 4px;
	border: 1px solid #d5e7f7;
	margin-right: 10px;
}

.course-test-container button {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	margin-right: 10px;
	border: none;
	padding: 5px 19px;
	text-transform: uppercase;
}
.test-btn {
	box-shadow: 0px 0px 20px rgba(238, 153, 73, 0.2);
	border-radius: 3px;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
	text-transform: capitalize;
	margin-top: 2rem;
}
.previous-btn {
	background: #ee9949;
}

.next-btn {
	    background: #dd574e;
}
.submit-btn {
	background: #12920d;
}
.course-test-name {
	background: #2e5dad;
	padding: 14px 15px;
	color: #fff;
	font-size: 16px;
}
.number-of-questions-box {
	background: #faf9fd;
	border-bottom: 1px dashed #c4d9ed;
	padding: 27px 16px 10px;
	border-left: 2px solid #fff;
}

.total-question-number-box {
	display: block;
	height: 100%;
	width: 100%;
	margin-top: 30px;
}
.number-of-questions-box h2 {
	font-weight: 600;
	font-size: 18px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #12274b;
}
.course-test-option .for-wrong-answer {
	position: absolute;
	right: 12px;
	top: 8px;
	font-size: 16px;
	color: #ff6465;
}

.course-test-option .for-correct-answer {
	position: absolute;
	right: 12px;
	top: 8px;
	font-size: 16px;
	color: #5bc716;
}

.answer-details-heading {
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 30px !important;
	color: #ee9949 !important;
	margin-bottom: 0px !important;
	position: relative;
}

.answer-details-heading span {
	color: #12274b !important;
}

.answer-details-box {
	box-shadow: 0px 5px 30px rgba(223, 228, 249, 0.5);
	margin-top: 0px;
	padding: 12px 9px;
	border-radius: 7px;
}

.answer-details-box .answer-details-text {
	font-size: 12px !important;
	line-height: 20px !important;
	color: #819ba6 !important;
	margin-bottom: 0px !important;
	font-weight: 400 !important;
	margin-top: 5px;
}

.answer-details-heading .answer-details-collaps {
	position: absolute;
	right: 4px;
	font-size: 20px;
}

.total-score-box {
	background: #faf9fd;
	border-bottom: 1px dashed #c4d9ed;
	padding: 20px 16px 20px;
	border-left: 2px solid #fff;
}

.total-score-box p {
	background: #ffffff;
	border: 1px solid #d5e7f7;
	box-sizing: border-box;
	display: block;
	overflow: auto;
	padding: 6px 8px;
	margin-bottom: 0px;
	font-weight: 500;
	font-size: 14px;
	line-height: 30px;
	text-align: right;
	color: #1d458a;
}

.number-of-questions-ans {
	background: #fff;
	border-bottom: 1px dashed #c4d9ed;
	padding: 20px 16px 20px;
	border-left: 2px solid #fff;
}

.number-of-questions-ans h2 {
	font-weight: 600;
	font-size: 15px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #ee9949;
}

.number-of-questions-ans h2 span {
	color: #1d458a;
}

.number-of-questions-ans .total-question-number-box {
	display: block;
	height: 100%;
	width: 100%;
	margin-top: 30px;
}

.number-of-questions-ans .total-question-number-box button {
	border: none;
	background: #cbd3d7;
	border: 1px solid #819ba6 !important;
	box-sizing: border-box;
	border-radius: 3px;
	width: 36px;
	text-align: center;
	height: 26px;
	font-size: 12px;
	color: #6378ae;
	font-weight: 500;
	margin-bottom: 6px;
}

.number-of-questions-ans .total-question-number-box button.correct-q {
	background: #d0f0c2 !important;
	border: 1px solid #5bc716 !important;
	color: #3d9c00 !important;
}

.number-of-questions-ans .total-question-number-box button.wrong-q {
	background: #f9cfd2 !important;
	border: 1px solid #ea6f70 !important;
	color: #d75050 !important;
}

.number-of-questions-ans .question-Attempted-box ul li span {
	background: #819ba6;
	border: 1px solid#819BA6;
	box-sizing: border-box;
	border-radius: 1px;
	display: block;
	width: 13px;
	float: left;
	height: 13px;
	position: relative;
	top: 2px;
	margin-right: 5px;
}

.number-of-questions-ans .question-Attempted-box ul li span.correct-q {
	background: #5bc716;
	border: 1px solid #5bc716;
}

.number-of-questions-ans .question-Attempted-box ul li span.wrong-q {
	background: #ea6f70;
	border: 1px solid #ea6f70;
}

.Leaderboard-section {
	background: #faf9fd;
	border-bottom: 1px dashed #c4d9ed;
	padding: 20px 16px 20px;
	border-left: 2px solid #fff;
}

.Leaderboard-section table {
	width: 100%;
	border-collapse: collapse;
}

.Leaderboard-section table,
td,
th {
	border: 1px solid #d5e7f7;
}

.Leaderboard-section table,
th,
td {
	font-size: 12px;

	color: #819ba6;
	padding: 7px;
}

.Leaderboard-section h2 {
	font-weight: 600;
	font-size: 18px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #12274b;
	margin-bottom: 20px;
}

.total-question-number-box button {
	border: none;
	background: #ffffff;
	border: 1px solid #d5e7f7;
	box-sizing: border-box;
	border-radius: 3px;
	width: 36px;
	text-align: center;
	height: 26px;
	font-size: 12px;
	color: #6378ae;
	font-weight: 500;
	margin-bottom: 6px;
	margin-right: 5px;
}

.total-question-number-box button.Attempted-q {
	background: #fbeece;
	border: 1px solid rgba(238, 153, 73, 0.29);
}

.total-question-number-box button.current-q {
	background: #2e5dad;
	border: 1px solid #2e5dad;
	color: #fff;
}

.question-Attempted-box {
	margin-top: 20px;
}

.question-Attempted-box ul {
	padding: 0px;
	list-style: none;
	display: block;
	overflow: auto;
	width: 100%;
}

.question-Attempted-box ul li {
	float: left;
	font-size: 11px;
	text-transform: capitalize;
	color: #819ba6;
	padding: 0px 6px;
}

.question-Attempted-box ul li span {
	background: #ffffff;
	border: 1px solid #d5e7f7;
	box-sizing: border-box;
	border-radius: 1px;
	display: block;
	width: 13px;
	float: left;
	height: 13px;
	position: relative;
	top: 2px;
	margin-right: 5px;
}

.question-Attempted-box ul li span.Attempted-q {
	background: #fbeece;
	border: 1px solid rgba(238, 153, 73, 0.29);
}

.question-Attempted-box ul li span.current-q {
	background: #2e5dad;
	border: 1px solid#2E5DAD;
}

.test-submit-btn {
	background: #ee9949;
	box-shadow: 0px 0px 20px rgba(238, 153, 73, 0.2);
	border-radius: 3px;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff !important;
}
.course-test-no {
	/* width: 90%; */
	text-align: right;
	margin: 9px auto;
	font-size: 14px;
	font-weight: 600;
	color: #10a529;
}
.course-result-container {
	height: 600px;
	margin: 10px 12px;
}
.answer-wrong {
	background-color: #df7721;
}
.answer-correct {
	background-color: #21df96;
}
.answer-wrong p {
	color: #000 !important;
	font-weight: 600 !important;
}
.answer-correct p {
	color: #000 !important;
	font-weight: 600 !important;
}
.webinar-full-video .video-js .vjs-tech {
	position: relative;
}
.webinar-full-video .html5-video-player .video-click-tracking,
.html5-video-player .video-stream {
	position: relative !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
}
.custom-toster {
	color: #fff;
	background: rgb(35, 35, 35);
	text-align: center;
	padding: 0px 10px;
	font-size: 17px;
	position: fixed;
	left: 0px;
	right: 0px;
	margin: auto;
	width: 330px;
	top: 23px;
	font-weight: 500;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.go-to-my-account {
	display: block;
    width: 43px;
    float: right;
    margin-right: 14px;
}
.goto-my-account-btn {
    background: rgb(238 153 73/27%);
    padding: 13px 17px;
    color: #12274b!important;
    font-weight: 600;
    border-radius: 7px;
    font-size: 12px;
}

.percentage-of-complete-in-header{
	font-weight: 600;
    font-size: 12px;
    line-height: 30px;
    color: #1D458A;
}
.side-menu-for-lms .ScrollbarsCustom-Wrapper {
	right: 0px !important;
}
.side-menu-for-lms .ScrollbarsCustom-Track {
	width: 5px !important;
	background: rgb(255 255 255 / 10%) !important;
}
.side-menu-for-lms .ScrollbarsCustom-Thumb {
	background: rgb(240 167 100 / 37%) !important;
}

.q-a-section {
	width: 100%;
	padding: 25px 10px;
	position: relative;
	border-bottom: 1px solid #d5e7f7;
}

.transcript_active{
  background-color: #a82828;
  color: white;
}

.user-image-q-and-a {
	width: 50px;
	border-radius: 50px;
	height: 50px;
	overflow: hidden;
	float: left;
	margin-right: 15px;
	border: 1px dashed #9db0b9;
	padding: 1px;
}

.user-image-q-and-a img {
	border-radius: 50%;
}
.user-question-q-and-a {
	overflow: hidden;
}

.q-and-a-question {
	width: 100%;
	display: block;
	float: left;
	padding-right: 24px;
}

.q-and-a-like {
	float: right;
	padding: 0px !important;
}

.q-and-a-like a {
	color: #000;
	margin-right: 6px;
	text-decoration: none;
}

.q-and-a-like a .fa {
	color: #bbb4b4;
}

.q-and-a-question .question {
	font-weight: 500;
	font-size: 16px;
	color: #ee9949;
	margin-bottom: 7px;
	height: auto;
}

.q-and-a-question .ans {
	font-style: italic;
	font-weight: normal;
	font-size: 13px;
	color: #819ba6;
	height: auto;
}

.question-date {
    color: #6378ae;
	overflow: auto;

}
.question-date span {
	font-size: 12px;
    padding: 10px 10px;
    display: inline-block;
}



.see-more-question-btn span {
	display: block;
	text-align: center;
	border: 1px solid #c5c5c5;
	min-width: 300px;
	margin: 20px auto 10px;
	padding: 9px 9px;
	font-size: 15px;
	font-weight: bolder;
	color: #565656;
	text-decoration: none;
	border-radius: 4px;
	cursor: pointer;
}

.question-reply-page .main-question {
	border: none;
}

.question-reply-page .q-a-section:hover {
	background: transparent;
}

.question-reply-page .q-a-section .q-and-a-question .question,
.question-reply-page .q-a-section .q-and-a-question .ans {
	height: auto;
}
.lms-dashboard-container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	margin-top: 50px;
	margin-bottom: 30px;
}

.display-none-side-menu-lms .lms-dashboard-container {
	max-width: 1100px;
}
.view-reply {
	cursor: pointer;
	color: #ee9949 !important;
}
.result-box {
	padding: 14px 10px;
	border-bottom: 1px solid #eee;
}
.result-box:hover {
	background-color: #ebedef;
}
.result-box div {
	padding: 8px;
}
.result-box div p {
	margin-bottom: 0px;
	font-weight: 500;
	color: #545454;
}
.course-result-container .result-box:first-child {
	border-top: 1px solid #eee;
}
.result-box > p {
	font-weight: 600;
	color: #000;
	font-size: 15px;
	padding: 6px;
	margin-bottom: 7px;
}
.course-result-container::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
}

.course-result-container::-webkit-scrollbar-track {
	box-shadow: none;
	background: transparent;
}

.course-result-container::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	border-radius: 10px;
	outline: none;
}
.course-result-container > span {
	display: block;
	font-size: 15px;
	color: #000;
	font-weight: 600;
	margin-bottom: 10px;
}
.course-result-container > p {
	padding: 7px 20px;
	border-radius: 7px;
	background: #f7f7f7;
	margin-top: 5px;
	overflow: auto;
	display: inline-block;
	font-size: 20px;
	color: #ee9949;
	font-weight: 600;
	border: 1px dashed #dedede;
}

.tips-for-question {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 25px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(20, 23, 28, 0.05);
	font-size: 13px;
	font-weight: 400;
	line-height: 23px;
	margin-top: 21px;
}

.ask-new-question {
	    background: #dd574e;
	box-shadow: 0px 0px 20px rgba(29, 69, 138, 0.3);
	border-radius: 3px;
	padding: 9px 18px;
	color: #fff;
}
.number-of-questions {
	color: #819ba6;
	font-weight: 500;
	font-size: 12px;
	margin-right: 15px;
}
.test-result-tab {
	border-bottom: 1px dashed #c4d9ed !important;
	padding-bottom: 1px;
	padding-left: 15px;
	padding-right: 15px;
}
.test-result-tab .nav-link {
	border: none !important;
	background: #fff;
	border-radius: 6px 6px 0 0;
	box-shadow: 0 0 30px rgb(173 201 224/38%);
	color: #819ba6 !important;
	margin-right: 10px;
	font-weight: 500;
	font-size: 14px;
	padding: 12px 31px;
}
.test-result-tab .nav-link.active {
	color: #ee9949 !important;
}
.webinar-plan-table {
	width: 100%;
	border-collapse: collapse;
	box-shadow: 0 0 7px rgb(223 228 249 / 54%);
	display: table;
}
.webinar-plan-table td,
th {
	border: 1px solid #d5e7f7;
	font-size: 14px;
	text-transform: capitalize;
	color: #819ba6;
	padding: 10px;
}
.webinar-plan-table th {
	background: rgba(220, 234, 249, 0.2);
	font-weight: 500;
	color: #12274b;
}
.webinar-plan-table td .plan-name {
	color: #ee9949;
}
.view-plan-btn {
	font-size: 12px;
	    background: #dd574e;
	border-radius: 5px;
	padding: 5px 10px;
	text-align: center;
	text-transform: capitalize;
	border: none;
	color: #fff;
}
.show-more-btn-center {
	position: relative !important;
	float: none !important;
	margin-top: 10px;
	margin-bottom: 0px;
}

.complete-img{
	width: 30px;
    float: left;
    margin-right: 8px;
    margin-top: 4px;
}
.course-progress{
	background: #fff;
    border: 1px solid #c4d9ed;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1px;
    width: 80%;
    float: left;
}
.course-progress .progressbar-progress{
	height: 3px !important;
	border-radius: 50px;
	background: #5BC716 !important;
}
.course-progress-box{
	overflow: auto;
    display: block;
    margin: 3px 0px 0px;
}
.course-progress-box .progress-percentage{
	font-size: 10px;
    float: left;
    margin-left: 9px;
	margin-top: -2px;
	color: #819BA6;
}



.form-control {
	font-size: 12px !important;
	border-radius: 10px;
	outline: none;
}
.course-result-container > span {
	display: block;
	font-size: 15px;
	color: #000;
	font-weight: 600;
	margin-bottom: 10px;
}
.course-result-container > p {
	padding: 7px 20px;
	border-radius: 7px;
	background: #f7f7f7;
	margin-top: 5px;
	overflow: auto;
	display: inline-block;
	font-size: 20px;
	color: #ee9949;
	font-weight: 600;
	border: 1px dashed #dedede;
}

.tips-for-question {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 25px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(20, 23, 28, 0.05);
	font-size: 13px;
	font-weight: 400;
	line-height: 23px;
	margin-top: 21px;
}

.ask-new-question {
	background: #dd574e;
	box-shadow: 0px 0px 20px rgba(29, 69, 138, 0.3);
	border-radius: 3px;
	padding: 9px 18px;
	color: #fff;
}
.number-of-questions {
	color: #819ba6;
	font-weight: 500;
	font-size: 12px;
	margin-right: 15px;
}
.header-for-pc{
	display: block;
}
.header-for-mobile{
	display: none;
	overflow: hidden;
    box-shadow: 0 0 11px rgb(0 0 0 / 17%);
    background: #fff;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
    padding: 6px 7px;
}
.header-for-mobile .logo-for-mobile{
	width: 35px;
    margin-top: 5px;
}
.header-for-mobile .right-sideopen-logo{
	width: 31px;
	margin-top: 7px;
}
.custom-dropedown-for-user{
	position: fixed !important;
    top: 74px !important;
    right: 2px !important;
    float: right;
    left: auto !important;
    transform: none !important;
}
.custom-dropedown-for-user .dropdown-item{
	line-height: 43px;
	margin-left: 0px;
	font-size: 1rem !important;
	color: #212529 !important;
	font-weight: inherit;
}
.custom-dropedown-for-user.my-account-profile{
	top: 51px!important;
}
.game-section-top-box{
	background: #fff;
    border: 1px solid #d5e7f7;
    box-sizing: border-box;
    box-shadow: 0 0 30px rgb(223 228 249 / 50%);
    border-radius: 7px;
    padding: 0 0 20px;
	overflow: hidden;
	position: relative;
}
.game-question{
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: #12274B;
}
.game-section{
	width: 80%;
	margin: 13px auto;
	text-align: center;
}
.game-section .game-answer-letter{
	background: rgba(220,230,235,.1);
    border: 1px solid #d5e7f7;
    box-sizing: border-box;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
	line-height: 30px;
	margin-left: 3px;
	margin-left: 3px;
	color: #FF901D;
    font-weight: 600;

}
.game-answer-block{
	overflow: auto;
    text-align: center;
}
.game-keyboard{

    text-align: center;
}
.game-keyboard .game-btn{
	background: #fff;
    box-shadow: 0 4px 10px #dfe4f9;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-left: 5px;
    border: none;
    margin-right: 5px;
    margin-top: 15px;
}
.top-header-lms-menu-tabs{
	padding: 9px 8px;
    border-radius: 6px 6px 0px 0px;

}
.game-keyboard .game-btn.success-btn{
	background: #D0F0C2;
	border: 1px solid #5BC716;
	box-sizing: border-box;
	box-shadow: 0px 4px 10px #DFE4F9;
	color: #3D9C00;
}
.game-keyboard .game-btn.wrong-btn{
	background: #F9CFD2;
	border: 1px solid #EA6F70;
	box-sizing: border-box;
	box-shadow: 0px 4px 10px #DFE4F9;
	color: #E8505B;
}
.game-next-btn{
	background: #ee9949;
    box-shadow: 0 0 20px rgb(238 153 73 / 20%);
    border-radius: 3px;
    border: none;
    padding: 7px 35px;
    color: #fff !important;
	margin: 30px auto 10px;
	width: 170px;
	position: absolute;
	right: 15px;
	bottom: 15px;
}
.game-question-attempt-box{
	background: rgba(220, 230, 235, 0.1);
    border: 1px solid #D5E7F7;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 12px;
	margin-bottom: 10px;
	position: relative;
}
.game-attempt-left{
	position: absolute;
    font-size: 9px;
    left: 0px;
    right: 0px;
    top: 12px;
	color: #000;
	text-align: center;
}
.game-question-attempt-box .progressbar-progress{
    height: 13px !important;
    border-radius: 50px;
}
.right-sideopen-btn{
	display: block;
    float: left;
    height: 45px;
    line-height: 45px;
	padding: 0px 10px 0px 0px;
    font-size: 20px;
	margin-right: 2px;
    position: relative;
    color: #ee9949;
}
.game-open-tagline{
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
    color: #ee9949;
    padding: 5px 20px;
    margin-bottom: -3px;
    margin-top: 27px;
}
.game-open-tagline span{
	color: #12274B;
}
.game-open-tagline-box{
	margin: 20px 20px;
    padding: 17px 34px;
    background: rgba(220,230,235,.1);
    border: 1px solid #d5e7f7;
    box-sizing: border-box;
    border-radius: 7px;
}
.game-open-tagline-box li{
	font-size: 14px;
    line-height: 22px;
    color: #6378AE;
    font-weight: 500;
}
.game-total-score-box{
	display: table;
    width: 100%;
    height: 100%;
}
.game-total-score-inner-box{
	display: table-cell;
    vertical-align: middle;
	text-align: center;
	width: 100%;
    height: 100%;
}
.game-total-score-inner-box p{
	color: #EE9949;
    margin-bottom: 1px;
}
.game-total-score-inner-box h1{
	color: #12274B;
    font-size: 33px;
    font-weight: 700;
}
.game-total-score{
	background: rgba(220, 230, 235, 0.1);
    border: 1px solid #D5E7F7;
    box-sizing: border-box;
    border-radius: 7px;
    display: inline-block;
    padding: 18px;
}
.mark-as-complete-checkbox{
	line-height: 37px;
    text-align: center;
    color: #819ba6;
    text-transform: capitalize;
    font-weight: 500;
}
.mark-as-complete-checkbox input{
	padding: 11px;
    width: 20px;
    height: 18px;
    border: none!important;
    outline: none;
    background: #524b4b;
    position: relative;
    top: 4px;
}
.complete-unit-name{
	text-align: center;
    font-weight: 500;
    color: #ee9949;
    font-size: 17px;
    margin-bottom: 0px;
    text-transform: uppercase;
}
.custom-dropedown-for-user .dropdown-item:active{
	background-color: #f8f9fa;
}
.total-review {
	font-size: 55px;
    margin-top: 37px;
    font-weight: 400;
    margin-bottom: -8px;
}
.star-rating{
	position: relative;
    height: 31px;
    width: 103px;
    overflow: hidden;
    text-align: center;
    margin: auto;
}
.side {
    float: left;
    width: 5%;
    margin-top: 0px;
    font-size: 10px;
    color: #ababab;
}
.middle {
    margin-top: 5px;
    float: left;
    width: 90%;
}
.right {
    text-align: right;
}
.side {
    float: left;
    width: 5%;
    margin-top: 0px;
    font-size: 10px;
    color: #ababab;
	position: relative;
    top: 16px;
	right : 10px;
}
.feedbackprogress{
	background: #eee;
    border-radius: 50px;
    overflow: hidden;
}
.feedbackprogress .progressbar-progress{
	background-color: #ee9949 !important;
	height: 15px !important;
}
.report-to-spam .dropdown-toggle::after{
	display: none;
}
.report-to-spam .dropdown-menu{
	margin: 0 !important;
    padding: 0;
    left: -6px !important;
    top: -25px !important;
}
.report-to-spam .dropdown-menu .dropdown-item{
	padding: 5px 0px;
    font-size: 12px;
    text-align: center;
}
.report-to-spam{
	display: inline-block;
	margin-left: 27px;
}
.elm-btn-primary-blue{
	background: #1D458A!important;
    color: #fff!important;
}

.elm-btn-primary-blue:disabled{
	background: #7b9eda!important;
    color: #fff!important;
}
.go-to-my-account{
	display: block;
    float: right;
    margin-right: 14px;
    background: #dd574e;
    color: #fff !important;
    height: 35px;
    position: absolute;
    right: 15px;
    top: 21px;
    line-height: 35px;
    width: 91px;
    text-align: center;
    box-shadow: 0px 0px 20px rgb(29 69 138 / 30%);
    border-radius: 5px;
    text-transform: uppercase;
}
.unit-description-style{
	text-align: center;
    font-size: 14px;
    color: #ee9949;
    font-weight: 600;
    width: 79%;
    margin: 24px auto 10px;
}
.like-dislike-btn-style a img{
	width: 30px;
    margin-right: 11px;
}
.course-rating-box{
	display: none;
    text-align: center;
    margin-bottom: 17px;
    margin-top: -40px;
}
/* .rate-this-course-text{
	margin: 0px;
	font-size: 14px;
    font-weight: 500;
	color: #12274b;
}
.rate-this-course-text .fa{
	color: #ee9949;
}
.course-rating-box .rate-this-course-text a{
	color: #1d458a!important;
    font-size: 14px;
    font-weight: 500;
}
.course-rating-box .rate-this-course-text a .fa{
    color: #ee9949;
    margin-left: 5px;

} */
.rate-this-course-text{
	color: #1d458a;
    font-weight: 500;
    font-size: 12px;
    margin-right: 7px;
    text-decoration: underline;
}
.check_icon_mobile_validation{
	border: 2px solid #ee9949;
	color: #ee9949;
	font-size: 25px;
	width: 50px;
	height: 50px;
	line-height: 50px;
	margin: auto; border-radius: 50px;
}
.mobile_paragraph{
	font-size: 1.3rem;
	color: #ee9949;
}
@media (max-width: 767px) and (min-width: 200px) {
	.top-header-style {
		height: 44px;
		line-height: 44px;
	}
	.elm-logo-e-learn {
		display: none;
	}
	.course-name-top-header a {
		font-size: 13px !important;
	}
	.back-button-e-learn {
		display: block;
	}
	.e-learning-content-section .previous-button {
		display: block;
		height: 43px;
		top: 39%;
		line-height: 43px;
		width: 23px;
		font-size: 21px;
	}
	.e-learning-content-section .next-button {
		display: block;
		height: 43px;
		top: 39%;
		line-height: 43px;
		width: 23px;
		font-size: 21px;
	}
	.course-name-top-header::before {
		height: 100%;
		top: 0px;
	}
	.left-section-for-elearn {
		width: 100%;
	}

	.e-learning-dashboard-section {
		margin-top: 0px;
	}
	.lms-controller-nav-bar li a {
		font-size: 14px;
	}
	.only-for-mobile {
		display: inline-block !important;
	}

	.section-class{
		width: 100%;
	}
	.right-section-for-elearn{
		position: absolute;
		width: 100%;
		background: #fff;
		top: 0px;
		bottom: 0px;
		height: 100%;
		z-index: 1030;
		transform: translateX(-34.4rem);
		transition: transform .15s cubic-bezier(.2,0,1,.9);
		left: 0;
	}
	.right-section-for-elearn.open-for-mobile-right-menu{
		transform: translateX(0);
		transition: transform .25s cubic-bezier(0,0,.38,.9);
	}
	.side-menu-for-lms{
		height: 100%;
	}
	.top-header-lms-menu{
		text-align: center;
	}
	.top-header-style {
		height: 44px;
		line-height: 44px;
	}
	.elm-logo-e-learn {
		display: none;
	}
	.course-name-top-header a {
		font-size: 13px !important;
	}
	.back-button-e-learn {
		display: block;
	}
	.e-learning-content-section .previous-button {
		display: block;
		height: 42px;
		top: auto;
		bottom: 12px!important;
		line-height: 43px;
		width: 37px;
		font-size: 21px;
	}
	.e-learning-content-section .next-button {
		display: block;
		height: 42px;
		top: auto;
		bottom: 12px!important;
		line-height: 43px;
		width: 37px;
		font-size: 21px;
	}
	.e-learning-content-section .previous-button {
		left: 10%;
	}
	.e-learning-content-section .next-button {
		right: 10%;
	}
	.course-name-top-header::before {
		height: 100%;
		top: 0px;
	}
	.left-section-for-elearn {
		width: 100%;
		overflow: hidden;
	}

	.e-learning-dashboard-section {
		margin-top: 0px;
	}
	.lms-controller-nav-bar li a {
		font-size: 14px;
	}
	.only-for-mobile {
		display: inline-block !important;
	}
	.header-for-pc{
		display: none;
	}
	.header-for-mobile{
		display: block;
	}
	.display-none-side-menu-lms .right-section-for-elearn {
		display: block;
	}
	.show-side-menu-lms-btn{
		display: none !important;
	}
	.number-of-questions {
		font-size: 15px;
		margin-right: 0px;
		display: block;
		text-align: center;
	}
	.ask-new-question {
		display: block;
		text-align: center;
		margin-top: 17px;
	}
	.lms-dashboard-container {
		margin: 19px auto 30px;
		padding: 0px;
	}
	.q-a-section {
		padding: 25px 0 10px;;
	}
	.question-date span {
		font-size: 10px;
		padding: 3px;
		display: inline-block;
		border: none !important;
	}
	.user-image-q-and-a {
		width: 35px;
		height: 35px;

	}
	.e-learning-content-section{
		padding: 81px 0px 56px;
	}
	.middle {
		width: 83%;
	}
	.feedbackprogress .progressbar-progress {
		height: 12px!important;
	}
	.total-review {
		font-size: 49px;
		margin-top: 22px;
	}
	.report-to-spam .dropdown-menu {
		margin: 0 !important;
		padding: 0;
		left: -6px !important;
		top: -25px !important;
	}
	.view-plan-btn {
		background: transparent;
		color: #dd574e !important;
		display: block;
	}
	.goto-my-account-btn{
		display: inline-block;
	}
	.unit-name-text{
		display: block;
		width: 90%;
	}
	.section-class-test{
		width: 100%;
	}
	.course-test-option{
		overflow: auto;
	}
	.course-test-option input{
		float: left;
		display: inline-block;
		width: 6%;
	}
	.course-test-option label{
		width: 88%;
		float: left;
		display: inline-block;
		margin: 0px;
	}
	.test-result-tab{
		display: block;
	}
	.test-result-tab .nav-link{
		display: block;
		padding: 14px 31px;
		border-radius: 0px;
		background: #fbfafa;
		background: #fff;
		box-shadow: 0 0 30px rgb(223 228 249 / 50%);
	}
	.test-result-tab .nav-link.active {
		color: #fff!important;
		background: #ee9949;
	}
	.course-test-option .for-correct-answer {
		right: 3px;
		top: 13px;
		font-size: 12px;

	}
	.webinar-plan-table{
		display: block;
	}
	.go-to-my-account{
		height: 35px;
		right: 5px;
		top: 7px;
		line-height: 35px;
		width: 35px;
		text-align: center;
		border-radius: 50px;

	}
	.like-dislike-btn-style a img{
		width: 33px;
		margin-right: 6px;
		margin-left: 8px;
		margin-top: 9px;
	}
	.percentage-of-complete-in-header-img{
		width: 107px;
		margin-top: 6px;
		margin-right: 3px;
	}
	.course-rating-box{
		margin-top: -9px;
		display: block;
		margin-bottom: 10px;
	}
}

@media (max-width: 2560px) and (min-width: 1300px) {
	.section-class {
		height: 400px;
	}
}
.my-btn-primary {
	background-color: #ee9949;
	color: #fff;
	border: none;
	padding: 7px 27px;
}
.my-btn-primary:hover {
	background-color: #f17a0a !important;
}
.form-control {
	font-size: 12px !important;
}

.end-screen-container {
	background: rgba(0, 0, 0, 0.5);
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 90%;
	top: 0;
}
.game-spacer{
	margin-left: .5rem;
}
.game-word-fill{
	background-color: #2c4d92!important;
}
.unit-name-active span.video-time {
    color: #000000!important;
}